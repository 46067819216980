import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
//import ServiceData  from "~data/startup/service"
import Content from './style'
import image1 from "~assets/image/media/AiAssist/svg/Time-SavingEfficiency.svg";
import image2 from "~assets/image/media/AiAssist/svg/PersonalizedOutreach.svg";
import image3 from "~assets/image/media/AiAssist/svg/EnhancedEngagement.svg";
import image4 from "~assets/image/media/AiAssist/svg/Increased-Success-Rate.svg";
import  ServicesCard from "../../../affiliates/ServicesOne/Components/Card-2"
import { SuperTag } from '~components';

export default function Benefits({backgroundColor,colClasses,...rest}){
return(
<Content {...rest}>
  <Container>

    <Row className="align-items-center justify-content-center justify-content-lg-start">
      <Content.TextBlock>
        <Content.Title as="h2"> <SuperTag value="What makes We-Connect different?"/> </Content.Title>
        
      </Content.TextBlock>
    </Row>
    
    <Row className="justify-content-center aos-init aos-animate" data-aos="fade-up" data-aos-duration={500} data-aos-delay={500} data-aos-once="true">
      {/* Single Services */}
      
              <Col xs="h2" className="col-xl-3 col-lg-3 col-sm-6 col-xs-8" >
                <ServicesCard
                  title="Advanced customizability"
                  text="Unlike competitors, We-Connect gives you complete access to campaign settings. Set individual action limits, randomize behavior, and match job titles using We-Connect. "
                  
                  iconBackground="#F3F4F6"
                />
              </Col>
              <Col xs="h2" className="col-xl-3 col-lg-3 col-sm-6 col-xs-8" >
                <ServicesCard
                  title="More campaign types"
                  text="You can share voice notes and files through We-Connect. Additionally, automate personalized celebration greetings to build rapport with leads."
                  
                  iconBackground="#F3F4F6"
                />
              </Col>
              <Col xs="h2" className="col-xl-3 col-lg-3 col-sm-6 col-xs-8" >
                <ServicesCard
                  title="LinkedIn compliance"
                  text="Keep your LinkedIn account secure by using your own IP address. We’ll also share regular updates on LinkedIn automation best practices."
                  
                  iconBackground="#F3F4F6"
                />
              </Col>
              <Col xs="h2" className="col-xl-3 col-lg-3 col-sm-6 col-xs-8" >
                <ServicesCard
                  title="Consistent updates"
                  text={<p>We release new features based on customer feedback monthly. Upvote and share what you’d like us to develop next over on our <a href='https://feedback.we-connect.io/announcements' target='_blank'>Ideas and Announcements page.</a></p>}
                  iconBackground="#F3F4F6"
                />
              </Col>
              
            
      {/*/ .Single Services */}
    </Row>

  </Container>
</Content>

)
}