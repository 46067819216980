import React from 'react'
import { Link } from '~components'
import Card from './style'
export default function ServicesCard({image, title, text,to,iconBackground,...rest}){
return(
<Card itemCenter={true} backgroundColor="#fff" iconBackground={iconBackground} {...rest}>
  
  <Card.Title as="h4">{title}</Card.Title>
  <Card.Text >{text}</Card.Text>
</Card>
)
}