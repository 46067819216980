import React from "react"
import { PageWrapper } from "~components"
import HeaderButton from '~sections/project/Header'
import CtaSection from "~sections/marketing/Cta"
import FooterOne from "~sections/marketing/FooterOne"
import Testimonial from "~sections/customer-stories/Testimonial"
import Hero from "~sections/project/Comparison/Hero"
import { Helmet } from "react-helmet"
import ComparisonChart from "~sections/project/Comparison/Comparison-chart/comparison-chart"
import Benefits from "~sections/project/Comparison/Benefits"

const header = {
    headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
    containerFluid:false,
    buttonBlock: (
      <HeaderButton
        className="ms-auto d-none d-xs-inline-flex"
        btnOneText="Login"
        btnTwoText="14 day free trial"
        mr="15px"
        mrLG="0"
      />
    ),
  }

  export default function Comparison() {
    return (
      <>
      <Helmet>
      <title>Compare We-Connect to Competitors</title>
      <meta name="description" content="Find out how We-Connect gets the upper hand on the competition with an up-to-date feature comparison chart."/>
      <meta property="og:title" content="Compare We-Connect to Competitors "/>
      <meta property="og:description" content="Find out how We-Connect gets the upper hand on the competition with an up-to-date feature comparison chart. "/>
      <meta property="og:type" content="website"/>
      <meta property="og:url" content="https://we-connect.io/compare-we-connect-to-competitors"/>
      <meta property="twitter:card" content="summary_large_image"/><meta property="twitter:site" content="@WeConnect_io"/>
      <meta property="twitter:title" content="Compare We-Connect to Competitors "/>
      <meta property="twitter:description" content="Find out how We-Connect gets the upper hand on the competition with an up-to-date feature comparison chart."/>
      <meta property="twitter:image" content="https://we-connect.io/compare-we-connect-to-competitors.png"/>
    </Helmet>
      <PageWrapper headerConfig={header}>
        <Hero />
        <ComparisonChart />
        <Benefits />
        <Testimonial />
        <CtaSection />
        <FooterOne />
      </PageWrapper>
      </>
    )
  }